import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import BotDetails from "../bot/bot-details";

function Layout() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BotDetails />} />
        <Route path="/:botId" element={<BotDetails />} />
      </Routes>
    </Router>
  );
}

export default Layout;
