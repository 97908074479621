import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/solid";

function BotDetails() {
  const { botId: botHash } = useParams<{ botId: string }>(); // Capture the botId from URL
  const [bot, setBot] = useState<any>(null);
  const [styles, setStyles] = useState<CSSProperties>({});
  const [showGreeting, setShowGreeting] = useState(true);
  const [greetingMessage, setGreetingMessage] = useState("");
  const [sendUrl, setSendUrl] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (botHash) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/getBotByBotHash`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Indicates that we're sending a JSON body
        },
        body: JSON.stringify({ bot_hash: botHash }),
      }) // Change this URL to your API
        .then((response) => response.json())
        .then((data: any) => {
          setIsLoading(false);
          setBot(data);
        })
        .catch(() => setIsLoading(false));
    }
  }, [botHash]);

  const botId = useMemo(() => {
    if (bot) {
      return bot.id;
    }
    return null;
  }, [bot]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sendUrl) {
        const el = document.getElementById("frase-iframe"); // Ensure the iframe has an ID
        console.log(el);
        const iframe = el as HTMLIFrameElement;
        console.log(iframe);
        if (iframe && iframe.contentWindow) {
          console.log("sending url to iframe: ", window.location.href);
          // Send a message to the iframe
          iframe.contentWindow.postMessage(
            {
              type: "URL_REQUEST",
              url: window.location.href,
            },
            "*"
          ); // Replace '*' with the specific domain of your iframe for security
        }
      }
    }, 1000); // Send the URL to the iframe every second

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [sendUrl]); // Add sendUrl as a dependency to re-run the effect when it changes

  useEffect(() => {
    if (botId) {
      window.addEventListener("message", (event) => {
        const message: any = event.data;
        console.log(message);
        switch (message.type) {
          case 0:
            setStyles((message as any).styles);
            break;
          case 1:
            if ((message as any).config.showGreeting !== undefined) {
              const greeting = (message as any).config.showGreeting;
              setShowGreeting((state) =>
                greeting !== undefined ? greeting : state
              );
            }

            setGreetingMessage(
              (state) => (message as any).config.greetingMessage || state
            );
            break;
          case 3:
            setSendUrl(false);
            break;
          default:
            console.debug("unhandled message", message);
        }
      });
    }
  }, [botId]);

  const boxShadow = useMemo(() => {
    if (styles && styles.height === "100vh") {
      return "-5px 0 5px -5px rgba(0, 0, 0, 0.1)";
    }
    return undefined;
  }, [styles]);

  if (bot && bot.id) {
    return (
      <div>
        <p style={{ fontWeight: "bold", fontSize: "24px" }}>
          Frase Answers Sandbox
        </p>
        <p>{bot.name} Preview</p>
        <div
          style={{
            ...styles,
            overflow: "hidden",
          }}
        >
          <iframe
            title="Chat Bot"
            id="frase-iframe"
            src={`${process.env.REACT_APP_IFRAME_URL}/${bot.orgHash}/override${
              botId ? "?bot-id=" + botId : ""
            }`}
            style={{
              ...styles,
              border: "none",
              position: "fixed",
              backgroundColor: "white",
              zIndex: 10000,
              boxShadow: boxShadow,
              right: 0,
              bottom: 0,
            }}
          ></iframe>
          {showGreeting && greetingMessage && (
            <div
              style={{
                position: "fixed",
                bottom: `calc(${styles.marginBottom} + 86px)`,
                right: "18px",
                backgroundColor: "#fff",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                zIndex: 10001,
                maxWidth: "300px",
                border: "1px solid #ddd",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "10px",
                  lineHeight: "16px",
                }}
              >
                {greetingMessage}
              </p>
              <button
                title="close greeting"
                onClick={() => setShowGreeting(false)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                <XMarkIcon
                  style={{ height: "16px", width: "16px", color: "#bbb" }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else if (isLoading) {
    return <div>Loading Bot...</div>;
  } else {
    return <div>404</div>;
  }
}

export default BotDetails;
